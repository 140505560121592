@import url(https://fonts.googleapis.com/css2?family=Source+Code+Pro:wght@600&display=swap);
.header{
   
padding: 20px;
text-align: left;
    
   
}
.header h1, h2, h3 {
    margin-top: 0;
    margin-bottom: 0.5rem;
    font-size: 1.5rem;
}
.header h2 {
    font-size: 1.3rem;
}

.header h3 {
    font-size: 1.1rem;
}
.project-detail a{
    padding: 0px 20px;
    text-decoration: none;
    
}

.project-detail a:hover {
    text-decoration: underline;
}

.project-detail p {
    padding: 0px 20px;
    font-size: 0.9rem;
    margin-top: 0px;
}

.project-list h1{
    margin-top: 0;
    padding: 0 20px;
    padding-bottom: 10px;
    font-size: 1.1rem;
}
.interests h1 {
    margin-top: 20px;
    padding: 0 20px;
    padding-bottom: 10px;
    font-size: 1.1rem;
}
.interests p {
    padding: 10px 20px;
}
.contact h1 {
    font-size: 1.1rem;
    padding: 20px;
}

.contact a {
    text-decoration: none;
    display: block;
    padding: 0 20px;
}

.contact a:hover {
    text-decoration: underline;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

body {
  background-color: #F2F2F2;
  font-family: 'Source Code Pro', monospace;
  line-height: 1.8em;
  padding: 1rem;
  /**color: #5D6063; **/
  color: 	#343434;
  display: flex;
  justify-content: center;
  max-width: 500px;
  margin: 0px auto;
  text-align: left;
 font-size: 16px;
}



@media only screen and (max-width: 400px) {
    
}

@media only screen and (min-width: 401px) and (max-width: 960px) {
   
}
@media only screen and (min-width: 961px) {
   
}
