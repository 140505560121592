.contact h1 {
    font-size: 1.1rem;
    padding: 20px;
}

.contact a {
    text-decoration: none;
    display: block;
    padding: 0 20px;
}

.contact a:hover {
    text-decoration: underline;
}