.project-detail a{
    padding: 0px 20px;
    text-decoration: none;
    
}

.project-detail a:hover {
    text-decoration: underline;
}

.project-detail p {
    padding: 0px 20px;
    font-size: 0.9rem;
    margin-top: 0px;
}
