
@import url('https://fonts.googleapis.com/css2?family=Source+Code+Pro:wght@600&display=swap');

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

body {
  background-color: #F2F2F2;
  font-family: 'Source Code Pro', monospace;
  line-height: 1.8em;
  padding: 1rem;
  /**color: #5D6063; **/
  color: 	#343434;
  display: flex;
  justify-content: center;
  max-width: 500px;
  margin: 0px auto;
  text-align: left;
 font-size: 16px;
}



@media only screen and (max-width: 400px) {
    
}

@media only screen and (min-width: 401px) and (max-width: 960px) {
   
}
@media only screen and (min-width: 961px) {
   
}